import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import PageHeading from "../../components/page_heading";
import { Grid, CheeseItem } from "../../components/cheese_gallery";

const CheesePage = ({ data }) => {
  const images = data.allFile.nodes.map((node) => <CheeseItem node={node} />);
  return (
    <Layout iconName="cheese">
      <PageHeading headingText="Cheeses!" />
      <Grid>{images}</Grid>
    </Layout>
  );
};

export const query = graphql`
query {
  allFile(filter: {sourceInstanceName: {eq: "cheese"}, name: {eq: "index"}}) {
    nodes {
      childMdx {
        frontmatter {
          title
          extra_images {
            childImageSharp {
                gatsbyImageData
            }
            name
          }
        }
        slug
      }
    }
  }
}

`;

export default CheesePage;
